import React from "react";

import * as Icon from "react-feather";

export const navigationConfigCRM = [
  {
    type: "groupHeader",
    groupTitle: "Home",
  },
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/",
  },
  {
    type: "groupHeader",
    groupTitle: "Main",
  },
  {
    id: "access",
    title: "Access",
    icon: <Icon.Unlock size={20} />,
    type: "collapse",
    permissions: ["developer", "admin", "manager", "promoter*", "teacher*"],
    children: [
      {
        id: "access-staff",
        title: "Staff",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["developer", "admin", "manager", "promoter*", "teacher*"],
        navLink: "/access/staff",
      },
      /*{
        id: 'access-user',
        title: 'User',
        icon: <Icon.Circle size={12} />,
        type: 'item',
        permissions: ['developer', "admin", "manager"],
        navLink: '/access/users'
      }*/
    ],
  },
  {
    id: "accounts",
    title: "Accounts",
    icon: <Icon.User size={20} />,
    type: "collapse",
    permissions: ["developer", "admin", "manager", "promoter*", "teacher*"],
    children: [
      {
        id: "accounts-students",
        title: "Students",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["developer", "admin", "manager", "promoter*", "teacher*"],
        navLink: "/accounts/students",
      },
      {
        id: "accounts-staff",
        title: "Staff",
        icon: <Icon.Circle size={12} />,
        type: "item",
        permissions: ["developer", "admin", "manager", "teacher*"],
        navLink: "/accounts/staff",
      },
    ],
  },
  {
    id: "checkings",
    title: "Checkings",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    permissions: ["developer", "admin", "manager", "promoter*", "teacher"],
    navLink: "/checkings",
  },
  /*{
    id: 'courses',
    title: 'Courses',
    type: 'item',
    icon: <Icon.BookOpen size={20} />,
    permissions: ["developer", "admin", "teacher"],
    navLink: '/courses'
  },*/
  {
    id: "customers",
    title: "Customers",
    icon: <Icon.Users size={20} />,
    type: "item",
    permissions: [
      "developer",
      "admin",
      "manager",
      "promoter",
      "teacher",
      "assistant",
    ],
    navLink: "/customers",
  },
  {
    id: "cycles",
    title: "Cycles",
    icon: <Icon.RefreshCw size={20} />,
    type: "item",
    permissions: [
      "developer",
      "admin",
      "manager",
      "promoter",
      "teacher",
      "reception",
      "assistant",
    ],
    navLink: "/cycles",
  },
  {
    id: "meetings",
    title: "Meetings",
    type: "item",
    icon: <Icon.Coffee size={20} />,
    permissions: [
      "developer",
      "admin",
      "manager",
      "promoter",
      "teacher",
      "reception",
    ],
    navLink: "/meetings",
  },
  {
    id: "payments",
    title: "Payments",
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    permissions: ["developer", "admin", "promoter", "manager", "assistant*"],
    navLink: "/payments",
  },
  {
    id: "potentials",
    title: "Potentials",
    icon: <Icon.UserPlus size={20} />,
    type: "item",
    permissions: ["developer", "admin", "promoter", "manager"],
    navLink: "/potentials",
  },
  {
    id: "reservations",
    title: "Reservations",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: ["developer", "admin", "manager", "teacher"],
    navLink: "/reservations",
  },
  {
    id: "recorder",
    title: "Recorder",
    type: "item",
    icon: <Icon.Voicemail size={20} />,
    permissions: ["developer", "admin", "manager", "teacher*"],
    navLink: "/recorder",
  },
  {
    id: "students",
    title: "Students",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["reception"],
    navLink: "/students",
  },
  {
    id: "timetable",
    title: "Timetable",
    type: "item",
    icon: <Icon.Tablet size={20} />,
    permissions: ["developer", "admin", "manager", "promoter", "teacher"],
    navLink: "/timetable",
  },
  {
    type: "groupHeader",
    groupTitle: "Web",
    permissions: ["developer", "admin", "manager"],
  },
  {
    id: "events",
    title: "Events",
    type: "item",
    icon: <Icon.Star size={20} />,
    permissions: ["developer", "admin", "manager"],
    navLink: "/events",
  },
  {
    id: "pages",
    title: "Pages",
    type: "item",
    icon: <Icon.Layout size={20} />,
    permissions: ["developer", "admin", "manager"],
    navLink: "/pages",
  },
  {
    type: "groupHeader",
    groupTitle: "Other",
  },
  {
    id: "import-export",
    title: "Import/Export",
    type: "collapse",
    permissions: ["developer", "admin", "manager", "promoter"],
    icon: <Icon.Download size={20} />,
    children: [
      {
        id: "manual-upload",
        title: "Manual Upload",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager", "promoter*"],
        navLink: "/potentials/upload-with-sheet",
      },
      {
        id: "facebook-leads",
        title: "Facebook Leads",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager", "promoter"],
        navLink: "/potentials/facebook",
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    type: "collapse",
    permissions: ["developer", "admin", "manager", "promoter*"],
    icon: <Icon.BarChart2 size={20} />,
    children: [
      {
        id: "reports-checkings",
        title: "Checkings",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager", "promoter*"],
        navLink: "/reports/checkings",
      },
      {
        id: "reports-customers",
        title: "Customers",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager"],
        navLink: "/reports/customers",
      },
      {
        id: "reports-cycles",
        title: "Cycles",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager", "promoter*"],
        navLink: "/reports/cycles",
      },
      {
        id: "reports-demographics",
        title: "Demographics",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager", "promoter*"],
        navLink: "/reports/demographics",
      },
      {
        id: "reports-opt",
        title: "OPT",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager", "promoter*"],
        navLink: "/reports/opt",
      },
      {
        id: "reports-potentials",
        title: "Potentials",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager", "promoter*"],
        navLink: "/reports/potentials",
      },
      {
        id: "reports-products",
        title: "Products",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager"],
        navLink: "/reports/products",
      },
      {
        id: "reports-revenue",
        title: "Revenue",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager", "promoter*"],
        navLink: "/reports/revenue",
      },
      {
        id: "reports-source",
        title: "Source",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager"],
        navLink: "/reports/sources",
      },
      {
        id: "reports-timetable",
        title: "Timetable",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "teacher*"],
        navLink: "/reports/timetable",
      },
    ],
  },
  {
    id: "settings",
    title: "Settings",
    type: "collapse",
    permissions: ["developer", "admin", "manager"],
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: "categories",
        title: "Categories",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager"],
        navLink: "/categories",
      },
      {
        id: "devices",
        title: "Devices",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager"],
        navLink: "/devices",
      },
      {
        id: "products",
        title: "Products",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin", "manager"],
        navLink: "/products",
      },
      {
        id: "sources",
        title: "Sources",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["developer", "admin"],
        navLink: "/sources",
      },
    ],
  },
  {
    id: "assistance",
    title: "Assistance",
    type: "external-link",
    icon: <Icon.LifeBuoy size={20} />,
    navLink: "mailto:support@escapecampusuk.com",
  },
];

export const navigationConfigApp = [
  {
    type: "groupHeader",
    groupTitle: "Home",
  },
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/",
  },
  {
    type: "groupHeader",
    groupTitle: "Main",
  },
  {
    id: "access",
    title: "Access",
    type: "item",
    icon: <Icon.Unlock size={20} />,
    permissions: ["developer", "admin", "manager"],
    navLink: "/access",
  },
  {
    id: "activities",
    title: "Activities",
    type: "item",
    icon: <Icon.Voicemail size={20} />,
    permissions: ["developer", "admin", "manager", "teacher"],
    navLink: "/activities",
  },
  {
    id: "courses",
    title: "Courses",
    type: "item",
    icon: <Icon.Feather size={20} />,
    permissions: ["developer", "admin", "manager", "teacher"],
    navLink: "/courses",
  },
  {
    id: "plans",
    title: "Plans",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["developer", "admin", "manager", "teacher"],
    navLink: "/plans",
  },
  {
    id: "reviews",
    title: "Reviews",
    type: "item",
    icon: <Icon.Star size={20} />,
    permissions: ["developer", "admin", "manager", "teacher"],
    navLink: "/reviews",
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["developer", "admin", "manager"],
    navLink: "/users",
  },
  {
    type: "groupHeader",
    groupTitle: "Other",
  },
  {
    id: "assistance",
    title: "Assistance",
    type: "external-link",
    icon: <Icon.LifeBuoy size={20} />,
    navLink: "mailto:support@escapecampusuk.com",
  },
  {
    id: "languages",
    title: "Languages",
    type: "item",
    permissions: ["developer", "admin"],
    icon: <Icon.Globe size={20} />,
    navLink: "/languages",
  },
  {
    id: "tags",
    title: "Tags",
    type: "item",
    permissions: ["developer", "admin"],
    icon: <Icon.Tag size={20} />,
    navLink: "/tags",
  },
  {
    id: "settings",
    title: "Settings",
    type: "item",
    permissions: ["developer", "admin"],
    icon: <Icon.Settings size={20} />,
    navLink: "/settings",
  },
];
