export const PROD = process.env.NODE_ENV === "production";

export const backendCRMAPI = PROD
  ? "https://www.condedaw.com/EscapeVision-PHP/api"
  : "http://localhost:8080/api";

export const backendAppAPI_V1 = PROD
  ? "https://api2.escapecampusuk.com/api/v1"
  : "http://localhost:8000/api/v1";

export const googleClientId =
  "118336058531-lbc7uvj752lsnn3ek1nhugfne8o5kpsn.apps.googleusercontent.com";

export const STRIPE_CLIENT_ID = PROD
  ? "AR8xlKsQlMcTnNk6s8L703Y1alO7V7CPXCRo9y74UDBjDahAA7n3pUIoXNy_EjyW1Fo1OfE8u-vkoxdi"
  : "AfvdVMRfIYBF8RX69TC9tk6bVPlXNVsXQtIkgNKPltCQxGmfhpRgQ4SkIjvNG4sGeRuaxaNIZR4FJ9I2";

export const LEARNCUBE_PUBLIC_KEY = "e9b47ae4e2ed3f8c404ab914";
